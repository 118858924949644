<template>
    <div class="chat__box__content__area">
        <div class="chat__box__content" id="containerBox">

            <infinite-loading spinner="waveDots" direction="top" @infinite="getApplicantMessages">
                <div slot="no-more"></div>
                <div slot="no-results"></div>
            </infinite-loading>

            <div v-if="firstMessageDate && showFirstMessage" role="alert" class="alert fade show alert-warning text-center my-1 p-2 conversation__first__message">
                {{ $t('Conversation started on') }} {{ firstMessageDate.slice(0, 12) }}.
            </div>

            <div class="conversation" v-for="(message, index) in candidateMessages" v-bind:key="index" :class="message.id === lastMessageId ? 'active' : ''">
                <div class="conversation__thumb">
                    <message-user-picture :src="message.image" :alt="message.image"></message-user-picture>
                </div>
                <div class="conversation__text__group">
                    <div class="conversation__header">
                        <div class="title">
                            <p class="name">
                                <span :title="message.name" >{{ shortConversationOwnerName(message.name, 25) }}</span>
                                <span v-if="message.role" class="role">{{ message.role }}</span>
                            </p>
                        </div>
                        <span class="time">{{ message.created_at }}</span>
                    </div>
                    <div class="text ej-ck-editor" style="white-space: pre-line" v-if="message.message"
                       v-html="transformUrlToLink(message.message)"></div>
                    <div class="attachment__wrap" v-if="message.attachment">
                        <div class="attachments">
                            <a :href="message.attachment" target="_blank" class="attached__item">
                                <div class="icon">
                                    <i :class="message.media_icon"></i>
                                </div>
                                <div class="content">
                                    <p class="name">{{ message.media_name }}</p>
                                    <span class="size">{{ message.media_size }}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="message__box" :class="{'message__box__sm': isOpenNoteAndAttachment}">
            <div class="editor__wrapper ej-ck-editor" id="conversation-editor">
                <ckeditor @input="draftMessages" ref="conversation_message" :editor="editor" :config="conversationEditorConfig"
                          v-model.trim="form.message" @ready="onConversationReady">
                </ckeditor>
            </div>


            <div class="message__bottom">
                <div class="attachment__wrap" v-if="filename">
                    <p class="attachment">
                        {{ reduceLargeString }}
                        <a href="javascript:void(0)" @click="removeFile"><i class="eicon e-delete"></i></a>
                    </p>
                </div>
                <div class="button__group">
                    <label class="attachment__input">
                        <input type="file" ref="file" name="attach" :key="fileKey" @change="attachFile">
                        <span class="social-button semi-button-primary"><i class="eicon e-attachment"></i></span>
                    </label>
                    <conversation-submit-button :click="sendMessage" :loading="isLoading">{{ $t("Send") }}</conversation-submit-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ConversationSubmitButton from "../buttons/ConversationSubmitButton";
import client from "../../app/api/Client";
import InfiniteLoading from "vue-infinite-loading";
import {mapActions, mapState} from "vuex";
import {debounce} from "lodash"
import Vue from "vue";
import MessageUserPicture from "./MessageUserPicture";
import { apiCallScheduler, apiCallTimer } from "../../extra/messageConversationHelper"
import {ckEditorConversationOptions} from "../../config/options";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import {LOAD_CONVERSATION_CANDIDATES, LOAD_MESSAGE_NOTIFICATION, UPDATE_PAGE_STATE} from "../../constants/action-type";

export default {
    name: "ConversationChat",

    props: ['isOpenNoteAndAttachment'],

    data() {
        return {
            candidateMessages: [],
            errors: {
                message: '',
                file: ''
            },
            form: {
                message: '',
                file: '',
            },
            filename: '',
            isLoading: false,
            messagePage: 1,
            fileKey: null,
            showFirstMessage: false,
            firstMessageDate: null,
            lastMessageId: null,
            editor: DecoupledEditor,
            ckeditorInstance: null
        }
    },

    components: {
        InfiniteLoading,
        ConversationSubmitButton,
        MessageUserPicture,
        ckeditor: CKEditor.component
    },

    computed: {
        ...mapState(['company', 'pageState']),

        reduceLargeString() {
            let val = this.filename;
            if (val.length > 10) {
                return '...' + val.substring(val.length - 10, val.length);
            }
            return val;
        },

        conversationEditorConfig() {
            let placeholderConfig = {'placeholder': this.$t(`Type your message here`)};
            return {...ckEditorConversationOptions, ...placeholderConfig};
        },

        applicantId() {
            return this.pageState.applicantId;
        }

    },
    methods: {

        ...mapActions([LOAD_MESSAGE_NOTIFICATION, LOAD_CONVERSATION_CANDIDATES, UPDATE_PAGE_STATE]),

        async getApplicantMessages($state) {

            try {
                let queryParams = {params: {page: this.messagePage}};

                let applicant, notes;

                if(this.company) {
                    ({data: {data: {applicant, notes}}} = await this.getCompanyApplicantMessages(queryParams));
                } else {
                    ({data: {data: {applicant, notes}}} = await this.getCandidateApplicantMessages(queryParams));
                }

                if(!this.company && this.messagePage <= 1) {
                    await this.getConversationNotification();
                } else {
                    this[UPDATE_PAGE_STATE](notes);
                }

                await this.setUserInfo(applicant);

                this.form.message = applicant.draft_message;

                if(applicant.messages.data.length > 0) {
                    this.candidateMessages.unshift(...applicant.messages.data);
                    this.firstMessageDate = applicant.messages.data[0].created_at;

                    $state.loaded();

                    if (this.messagePage < applicant.messages.last_page) {
                        this.messagePage = applicant.messages.current_page + 1;
                    } else {
                        this.showFirstMessage = true;
                        $state.complete();
                    }
                } else {
                    $state.complete();
                }

            } catch ({response: {data: {status, message}}}) {
                if (status === 'FAILED') {
                    this[UPDATE_PAGE_STATE]({applicantId:null})
                }
            }
        },

        async getCompanyApplicantMessages(params) {
            return await client().get(`conversation/applicants/` + this.applicantId, params);
        },

        async getCandidateApplicantMessages(params) {
            return await client().get(`candidate/conversation/` + this.applicantId, params);
        },

        async sendMessage() {
            if (this.checkValidMessage()) {

                apiCallScheduler.clearFn();

                this.isLoading = true;

                await this.saveMessage();

                this.ckeditorInstance.focus();

                apiCallScheduler.setFn(setTimeout(async () => {
                    await this.getHasConversationApplicantList();
                    await this.getConversationNotification();
                }, apiCallTimer));

                this.isLoading = false;
            }
        },

        checkValidMessage() {
            return this.form.message !=='' || this.form.file !== '';
        },

        async saveMessage() {
            const formData = new FormData();
            formData.append('file', this.form.file);
            formData.append('message', this.form.message);

            try {
                let applicant, lastMessage;

                if(this.company) {
                    ({data : {data: { applicant, lastMessage }}} = await this.saveCompanyMessage(formData));
                } else {
                    ({data : {data: { applicant, lastMessage }}} = await this.saveCandidateMessage(formData));
                }

                if(applicant.id) {
                    await this.setUserInfo(applicant);

                    if (applicant.messages.data.length) {
                        this.messagePage = applicant.messages.current_page;
                        this.candidateMessages = applicant.messages.data;
                    }
                }

                this.lastMessageId = lastMessage.id;
                this.clearMessage();

                setTimeout(() => {
                    this.lastMessageId = null
                }, 2000);

            } catch ({response: {data: {status, message}}}) {
                if (status === 'FAILED') {
                    this[UPDATE_PAGE_STATE]({applicantId:null})
                }
            } finally {
                Vue.nextTick(function () {
                    const div = document.getElementById('containerBox');
                    div.scrollTop = div.scrollHeight;
                })
            }
        },

        async saveCompanyMessage(formData) {
            return await client().post(`conversation/applicants/` + this.applicantId + `/message`, formData);
        },

        async saveCandidateMessage(formData) {
            return await client().post(`candidate/conversation/` + this.applicantId + `/message`, formData);
        },

        async setUserInfo(data) {
            let applicantDataEmit = {
                name: data.user?.name,
                stage: data.pipeline,
                image: data.user?.image,
                applicantid: data.id,
                title: data.job?.title,
                url: '',
                show_button: data.show_button,
                read: data.read
            };
            if (!this.company) {
                applicantDataEmit.name = data.company?.name;
                applicantDataEmit.image = data.company?.logo;
                applicantDataEmit.url = data.company?.url;
            }

            this.$emit('applicantData', applicantDataEmit);

            this[UPDATE_PAGE_STATE]({attachments: data.attachments});
        },

        clearMessage() {
            this.form.message = '';
            this.errors.message = '';
            this.form.file = '';
            this.errors.file = '';
            this.filename = '';
            const input = this.$refs.file;
            input.type = 'file';
            localStorage.removeItem(this.key);
        },

        removeFile() {
            this.form.file = '';
            this.filename = '';
        },

        attachFile() {
            this.fileKey = Math.random();
            this.errors.file = '';
            let FileSize = this.$refs.file.files[0].size / 1024 / 1024; // in MB
            if (FileSize > 2) {
                this.errors.file = this.$t(`File size should be under 2 mb!`);
                const input = this.$refs.file;
                input.type = 'text';
                input.type = 'file';
                this.$toast.error(this.$t(`File size should be under 2 mb!`));
            } else {
                this.form.file = this.$refs.file.files[0];
                this.filename = this.form.file.name;
            }
        },

        draftMessages: debounce(function () {
            client().post(`job/applicant/${this.applicantId}/draft-message`, {message: this.form.message});
        }, 1000),

        async getHasConversationApplicantList() {
            this[LOAD_CONVERSATION_CANDIDATES]({params: {page: 1, 'has-conversation': true}});
        },

        async getConversationNotification() {
            this[LOAD_MESSAGE_NOTIFICATION]();
        },

        shortConversationOwnerName(val, limit = 20) {
            if (val.length > limit) {
                return val.substring(0, limit);
            }
            return val;
        },

        onConversationReady(editor) {
            const toolbarContainer = document.querySelector('#conversation-editor');
            toolbarContainer.prepend(editor.ui.view.toolbar.element);
            editor.focus();

            this.ckeditorInstance = editor;
        }
    }
}
</script>

<style src="../../css/conversation-chat.css" scoped></style>
